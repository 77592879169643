import React, { Component } from 'react';
import Header from '../layout/header'
import FaqAccordion from '../element/faq-accordion'
import Footer from '../layout/footer'


const aboutbg = require('./../../assets/images/background/image-11.jpg');



class Faq extends Component {

 
    render() {
        return (
            <>
                <Header />

                {/* <!-- Page Banner Section --> */}
                <section className="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div className="bottom-rotten-curve alternate"></div>

                    <div className="auto-container">
                        <h1>Sıkça Sorulan Sorular</h1>
                    </div>

                </section>
                {/* <!--End Banner Section --> */}

                <FaqAccordion />


                

                <Footer />
            </>
        )
    }
}
export default Faq;