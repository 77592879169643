import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      alert: "",
    };
  }

  // handlePost(e) {
  //   e.preventDefault();
  //   if (this.state.name && this.state.email && this.state.message) {
  //     this.setState({
  //       alert: "Mesajınız gönderiliyor, lütfen bekleyiniz...",
  //     });
  //     try {
  //       const url = "https://api.ahazgroup.com/api/Mail";
  //       const options = {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({
  //           name: this.state.name,
  //           email: this.state.email,
  //           phone: this.state.phone,
  //           message: this.state.message,
  //         }),
  //       };
  //       return fetch(url, options).then(() => {
  //         this.setState({ alert: "Mesajınız gönderildi, teşekkür ederiz!" });
  //       });
  //     } catch (error) {
  //       return this.setState({
  //         alert:
  //           "Mesajınız gönderilemedi, lütfen tekrar deneyiniz ya da sol tarafta bulunan diğer iletişim yöntemlerini deneyiniz.",
  //       });
  //     }
  //   } else {
  //     return this.setState({
  //       alert: "Lütfen gerekli tüm alanları doldurunuz!!!",
  //     });
  //   }
  // }

  handlePost(e) {
    e.preventDefault();
    const config = {
      Host: "smtp.elasticemail.com",
      Username: "info@primeshields.com.tr",
      Password: "59365FFB7D86DC1C70C4324F504DDE732A7B",
      To: "info@primeshields.com.tr",
      From: this.state.email,
      Subject: `Merhaba ben ${this.state.name}.Sizinle iletişim kurmak istiyorum.`,
      Body: this.state.message,
    };

    if (this.state.name && this.state.email && this.state.message) {
      this.setState({
        alert: "Mesajınız gönderiliyor, lütfen bekleyiniz...",
      });
      if (window.Email) {
        window.Email.send(config)
          .then(() =>
            this.setState({ alert: "Mesajınız gönderildi, teşekkür ederiz!" })
          )
          .catch(() =>
            this.setState({
              alert:
                "Mesajınız gönderilemedi, lütfen tekrar deneyiniz ya da sol tarafta bulunan diğer iletişim yöntemlerini deneyiniz.",
            })
          );
      }
    } else {
      return this.setState({
        alert: "Lütfen gerekli tüm alanları doldurunuz!!!",
      });
    }
  }

  sendMail({ mailSubject, mailMessage }) {
    return (window.location.href =
      "mailto:" +
      "info@primeshields.com.tr" +
      "?subject=" +
      encodeURIComponent(mailSubject) +
      "&body=" +
      encodeURIComponent(mailMessage));
  }

  render() {
    return (
      <>
        <Header />

        {/* <!-- Page Banner Section --> */}
        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1>İletişim</h1>
            <ul className="bread-crumb clearfix"></ul>
          </div>
        </section>

        {/* <!-- Map Section --> */}
        <section className="map-section">
          <div
            className="map-column"
            style={{ height: "420px", width: "100%" }}
          ></div>
        </section>

        {/* <!-- Contact Section Two --> */}
        <section className="contact-section-two">
          <div className="auto-container">
            <div className="contact-info-area">
              <div className="contact-info">
                <div className="row">
                  <div className="info-column col-lg-3">
                    <span
                      className="text-dark contactIcon cursorPointer"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.sendMail({
                          mailSubject: "Tanışma",
                          mailMessage:
                            "Merhaba Prime Shields, \n \n Sizinle tanışmak istiyorum. \n Lütfen benimle iletişime geçiniz. \n \n Saygılarımla, \n",
                        })
                      }
                    >
                      <div className="icon-box">
                        <div className="icon">
                          <span className="flaticon-email-6"></span>
                        </div>
                        <h3>Mail</h3>
                        <br></br>
                        <ul>info@primeshields.com.tr</ul>
                      </div>
                    </span>
                  </div>
                  <div className="info-column col-lg-3">
                    <a
                      className=" contactIcon text-dark  "
                      target="_blank"
                      rel="noopener noreferrer"
                      href="tel:+905455536450"
                    >
                      <div className="icon-box">
                        <div className="icon">
                          <span className="flaticon-call-1"></span>
                        </div>
                        <h3>Telefon</h3>
                        <ul>
                          <br></br>
                          <h4>0545 553 6450</h4>{" "}
                        </ul>
                      </div>
                    </a>
                  </div>
                  <div className="info-column col-lg-3">
                    <a
                      className="text-dark"
                      href="https://www.google.com/maps/place/1178.+Sk.+No:81,+06374+Ostim+Osb%2FYenimahalle%2FAnkara/@39.9691795,32.7467607,17z/data=!3m1!4b1!4m6!3m5!1s0x14d349bbfd2a56fb:0x2120250917516bc5!8m2!3d39.9691795!4d32.7467607!16s%2Fg%2F11csjhhjwz"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="icon-box">
                        <div className="icon">
                          <span className="flaticon-location"></span>
                        </div>
                        <h3>Adres</h3>
                        <ul>
                          <span className="text-dark contactIcon">
                            Ostim OSB Mah. 1178 Sok. Nu:75/5 Yenimahalle/Ankara
                          </span>{" "}
                        </ul>
                      </div>
                    </a>
                  </div>
                  <div className="info-column col-lg-3">
                    <a
                      className="text-dark"
                      href="https://www.instagram.com/primeshieldstr/?igshid=YmMyMTA2M2Y%3D"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="icon-box">
                        <div className="icon">
                          <span className="flaticon-instagram"></span>
                        </div>
                        <h3>Instagram</h3>
                        <ul>
                          <br></br>
                          <span className="text-dark text-left contactIcon">
                            primeshieldstr
                          </span>{" "}
                        </ul>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form-area">
              <div className="sec-title text-center">
                <div className="sub-title">Bizimle İletişime Geçin</div>
                {/* <h2>Bizimle İletişime Geçin</h2> */}
              </div>
              {/* <!-- Contact Form--> */}
              <div className="contact-form">
                <form
                  method="post"
                  action="http://azim.commonsupport.com/Finandox/sendemail.php"
                  id="contact-form"
                >
                  <div className="row clearfix">
                    <div className="col-md-6 form-group">
                      <label for="name">İsminiz</label>
                      <input
                        type="text"
                        name="username"
                        placeholder="İsim Giriniz..."
                        required=""
                        id="name"
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                          this.setState({ alert: "" });
                        }}
                      />
                      <i className="fas fa-user"></i>
                    </div>

                    <div className="col-md-6 form-group">
                      <label for="email">Mail Adresiniz</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Mail Adresi Giriniz..."
                        required=""
                        id="email"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                          this.setState({ alert: "" });
                        }}
                      />
                      <i className="fas fa-envelope"></i>
                    </div>

                    <div className="col-md-12 form-group">
                      <label for="message">Mesajınız</label>
                      <textarea
                        name="message"
                        placeholder="Mesajınızı Yazınız..."
                        id="message"
                        onChange={(e) => {
                          this.setState({ message: e.target.value });
                          this.setState({ alert: "" });
                        }}
                      ></textarea>
                      <i className="fas fa-edit"></i>
                    </div>

                    <div className="col-md-12 form-group">
                      <button
                        className="theme-btn btn-style-one"
                        type="submit"
                        name="submit-form"
                        onClick={(e) => this.handlePost(e)}
                      >
                        <span className="btn-title">Gönder</span>
                      </button>
                    </div>
                  </div>
                  {this.state.alert && (
                    <div>
                      <div className="form-message-warning mt-4" />
                      <div className="form-message-success text-dark">
                        {this.state.alert}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}
export default Contact;
