import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Index from "./pages/index";
import About from "./pages/hakkimizda";
import Contact from "./pages/iletisim";
import Faq from "./pages/faq";
import Portfolio2 from "./pages/galeri";
import Services2 from "./pages/prime";
import GarantiBelgesi from "./pages/garantiBelgesi";
import BackToTop from "./layout/backToTop";
import Guarantee from "./pages/guarantee";

class Router extends Component {
  render() {
    return (
      <HashRouter hashType={"noslash"}>
        {/* <BrowserRouter> */}
        <div className="page-wrapper">
          <Switch>
            <Route path="/" exact component={Index} />
            <Route path="/hakkimizda" component={About} />
            <Route path="/iletisim" component={Contact} />
            <Route path="/faq" component={Faq} />
            {/* <Route path="/protector" component={Portfolio1} /> */}
            <Route path="/galeri" component={Portfolio2} />
            <Route path="/prime" component={Services2} />
            <Route path="/sss" component={Faq} />
            <Route path="/garantiBelgesi" component={GarantiBelgesi} />
            <Route path='/guarantee' component={Guarantee} />
          </Switch>

          <BackToTop />
        </div>
      </HashRouter>
    );
  }
}

export default Router;
