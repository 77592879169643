import React, { useState } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Button, Form, Spinner, Table } from "react-bootstrap";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

const Guarantee = () => {
  const [number, setNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warn, setWarn] = useState(false);
  const [data, setData] = useState([]);
  const hanlePost = async () => {
    if (number) {
      setLoading(true);
      fetch(`https://primeshieldsapi.netlify.app/api/car?number=${number}`, { method: "GET", redirect: "follow" })
        .then(async (response) => {
          const tempData = await response.json();
          await setWarn(false);
          await setData(tempData);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setData([]);
          setWarn(true);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Header />
      <section className='page-banner'>
        <div className='page-banner-bg' style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
        <div className='bottom-rotten-curve alternate'></div>
        <div className='auto-container'>
          <h1>Garanti Belgesi Sorgulama</h1>
          <div>
            <Form.Control
              type='text'
              placeholder='Lütfen Garanti Kodunu Giriniz...'
              onChange={(e) => setNumber(e.target.value)}
              className='my-2'
            />
            <br />
            <Button onClick={() => hanlePost()} disabled={loading} style={{ width: `150px`, height: "50px" }}>
              {loading ? <Spinner animation='border' variant='light' /> : "Sorgula"}{" "}
            </Button>
          </div>
          <hr />
          {data.length > 0 ? (
            <Table striped bordered hover variant='light'>
              <thead>
                <tr>
                  <th>Garanti Kodu</th>
                  <th>Plaka</th>
                  <th>Araç Sahibi</th>
                  <th>Ürün</th>
                  <th>Garanti Başlangıç</th>
                  <th>Garanti Bitiş</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {data.map((item) => (
                    <td>{item}</td>
                  ))}
                </tr>
              </tbody>
            </Table>
          ) : (
            <div>
              {!loading && (
                <p>Lütfen {warn && <strong style={{ color: `red` }}>GEÇERLİ</strong>} Sorgu Numarası Giriniz.</p>
              )}
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Guarantee;
