import React from "react";
import garantiBelgesiImg from "../../assets/images/garantiBelgesiImg.png";
const garantiBelgesi = () => {
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("Prime_Shields_PPF-TPU_Garanti_Belgesi.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Prime_Shields_PPF-TPU_Garanti_Belgesi.pdf";
        alink.click();
      });
    });
  };
  return (
    <div className="text-center  " style={{ height: "1000px" }}>
      <div className=" w-100 d-inline-block" style={{ height: "20px" }}></div>
      <div className="">
        <img src={garantiBelgesiImg} alt="garantiBelgesi"></img>
      </div>
      <button
        className="btn btn-success mt-2 "
        onClick={onButtonClick}
        style={{ width: "50%", height: "50px" }}
      >
        Garanti Belgesi İndir
      </button>
    </div>
  );
};

export default garantiBelgesi;
