import React, { Component } from "react";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class About extends Component {
  render() {
    return (
      <>
        <Header />

        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1>Hakkımızda</h1>
            <ul className="bread-crumb clearfix"></ul>
          </div>
        </section>

        {/* About Section Two */}
        <section className="about-section-two">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      src={require("../../assets/images/resource/primeblack.jpeg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-box">
                  <div className="sec-title">
                    <div className="sub-title">Hakkımızda</div>
                    <p>
                      Müşterilerimizin memnuniyeti, işimizin temelidir. Bu
                      nedenle, filmlerimizin kalitesini ve dayanıklılığını
                      artırmak için sürekli olarak araştırma ve geliştirme
                      çalışmaları yapmaktayız. Ayrıca, müşterilerimize en iyi
                      hizmeti sunmak için, deneyimli ve uzman ekiplerimiz ile
                      çalışmaktayız.
                    </p>
                    <p>
                      Araç boya koruma filmlerimiz, araçların dış yüzeylerindeki
                      hasarları azaltır ve aracın orijinal görünümünü korur.
                      Ayrıca, filmlerimiz araçların değerinin korunmasına
                      yardımcı olur ve potansiyel alıcıların araca bakış
                      açısının artmasına yardımcı olur.
                    </p>
                    <p>
                      Bizimle çalışarak, aracınızı dış etkenlere karşı koruma
                      altına alabilirsiniz. Sunduğumuz ürünlerin kalitesi ve
                      hizmetimizin memnuniyeti, müşterilerimizin işbirliğimizi
                      uzun yıllar boyunca sürdürmelerine neden olmuştur.
                      İşimizde lider olmak için çalışıyoruz ve müşterilerimize
                      en iyi hizmeti sunmak için buradayız.
                    </p>
                  </div>
                  {/* <div className="author-info">
                    <div className="wrapper-box">
                      <h2>Aziz MAMEDOV</h2>
                    </div>
                    <div className="signature">
                      <img
                        src={require("../../assets/images/resource/sg1.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="author-info">
                    <div className="wrapper-box">
                      <h2>Ahmet HORANI</h2>
                    </div>
                    <div className="signature">
                      <img
                        src={require("../../assets/images/resource/sg2.png")}
                        alt=""
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* History Section */}
        <section className="history-section">
          <div className="auto-container">
            <div className="sec-title text-center light">
              <div className="sub-title">Tarihçe</div>
            </div>
            <Tab.Container defaultActiveKey="first">
              <Nav className="nav-tabs tab-btn-style-one">
                <Nav.Item>
                  <Nav.Link eventKey="first">2023</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">2020</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">2019</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">2018</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/news-2.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light">
                          <div className="sub-title">2023</div>
                          <h2>Prime Shields</h2>
                        </div>
                        <div className="text">
                          Kurulduğumuz günden bu yana Protector Shields ve Prime
                          Shields markalarıyla, Türkiye ve tüm dünyada faaliyet
                          göstermekteyiz.<br></br>
                          <br></br>
                          Ayrıca, Türkiye'de öncü olma hedefi taşıyan renkli TPU
                          ürünlerimiz arasında yerini almıştır. <br></br>
                          <br></br>
                          TPU, C-TPU, TPH, Cam Filmi ve Sunroof Filmi
                          ürünlerimiz ile araç koruma sektörünün tümüne hitap
                          etmeye devam ediyoruz.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/prime.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light">
                          <div className="sub-title">2020</div>
                          <h2>
                            Kendi Markamız <br />
                            Prime Shields
                          </h2>
                        </div>
                        <div className="text">
                          Bu sektörde tüm dünyada öncü olmak hedefiyle Türk
                          imzası taşıyan PRIME SHIELDS markasını oluşturduk.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/protector.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light">
                          <div className="sub-title">2019</div>
                          <h2>
                            Protector Shields <br />
                            Distribütörlüğü
                          </h2>
                        </div>
                        <div className="text">
                          Prime Shields olarak tüm dünyada faaliyet gösteren ve
                          merkezi ABD olan PROTECTOR SHIELDS markasının Türkiye
                          ve Orta Doğu Distribütörü olduk.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/prime.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light">
                          <div className="sub-title">2018</div>
                          <h2>Kuruluş</h2>
                        </div>
                        <div className="text">
                          Şirketimiz tüm Türkiye'ye kaliteli ve ekonomik boya
                          koruma ve cam filmleri sunmak maksadıyla kurulmuştur.
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}
export default About;
