import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

class FaqAccordion extends Component {
  render() {
    return (
      <>
        {/* <!-- Faq Section --> */}
        <section className="faq-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-5">
                <div className="content mb-5 mr-lg-5">
                  <div className="sec-title">
                    <div className="sub-title">Sıkça Sorulan Sorular</div>
                    <h2>Neden Biz ?</h2>
                    <div className="text">
                      Uzun yıllardan beri süregelen tecrübemiz ile birlikte,
                      alanında uzman kadromuz ile sürekli değişim ve yenilik
                      içerisindeyiz. Ürünlerimizin ülkemizde ve tüm dünyada
                      sorunsuz bir şekilde yıllardır kullanılıyor olması ise en büyük
                      güvencemizdir.
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-7">
                <Accordion preExpanded={[0]} allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Boya Koruma Filmi (PPF) nedir ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Boya koruma filmi, otomobiller ve diğer araçlar gibi
                        yüzeyleri çizilmelerden, çarpışmalardan, güneş
                        ışınlarından ve diğer zararlı etkilerden korumak için
                        kullanılan bir koruyucu film tabakasıdır. Genellikle
                        poliüretan veya poliüretan içeren bir malzemeden yapılır
                        ve yüzeye uygulandığında şeffaf ve ince bir tabaka
                        oluşturur.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Neden PPF kullanmalıyım ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Boya koruma filmleri, araçların değerini korumak için
                        önemli bir rol oynar ve aracın dış yüzeyindeki
                        çiziklerin, lekelerin ve diğer zararların önlenmesine
                        yardımcı olur. Ayrıca, koruma filmleri, araçların
                        yüzeylerinin parlaklığını ve rengini korur ve aracın
                        yeni görünümünü daha uzun süre muhafaza etmesine
                        yardımcı olur.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>TPU nedir ?</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        TPU boya koruma filmleri ise termoplastik poliüretan
                        malzemelerin yanı sıra termoplastik poliolefin
                        malzemelerin de kullanımını içeren bir film türüdür. Bu
                        malzemeler, çevresel faktörlere karşı dayanıklı, esnek
                        ve çizilmeye karşı dirençlidir. TPU filmler, TPH
                        filmlerden daha iyi bir UV direnci gösterir ve daha az
                        renk solması veya sararma eğilimindedirler. Ayrıca, TPU
                        filmler, yüzey pürüzlülüğü bakımından TPH filmlere göre
                        daha fazla pürüzsüzdürler ve daha kolay
                        temizlenebilirler.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>TPH nedir ?</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        TPH boya koruma filmleri, termoplastik poliüretan
                        malzemeden yapılmıştır. Bu malzeme, dayanıklı, esnek ve
                        çizilmeye karşı dirençli bir yapıya sahiptir. TPH
                        filmler, yüksek mukavemetli ve pürüzsüz bir yüzeye sahip
                        olma eğilimindedirler ve çiziklere karşı iyi bir koruma
                        sağlarlar. Ancak, TPH filmler bazen UV ışınlarına maruz
                        kaldıklarında sararma veya renk solması gösterebilirler.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        TPU ve TPH arasındaki fark nedir ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        TPH (Thermoplastic Polyurethane) ve TPU (Thermoplastic
                        Polyolefin) boya koruma filmleri arasındaki temel fark,
                        kullanılan malzemelerdir.TPU boya koruma filmleri, daha
                        uzun süre dayanıklı ve daha az renk solması veya sararma
                        eğilimindedirler. Ancak, TPH filmler, daha sert ve daha
                        pürüzsüz bir yüzeye sahip olabilirler. Hangi tür filmin
                        tercih edileceği, kişisel tercih ve uygulama koşullarına
                        bağlıdır.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Cam filmi nedir ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Araç cam filmi, otomobil camlarına uygulanan bir film
                        tabakasıdır. Bu film tabakası, camın iç yüzeyine
                        yapıştırılarak, aracın iç mekanını güneş ışınlarından
                        korur ve aynı zamanda aracın içindeki sıcaklığı düşürür.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {" "}
                        Cam filminin faydaları nedir ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        {" "}
                        Araç cam filmlerinin birçok faydası vardır. Bunlar
                        arasında güneş ışınlarından koruma, aracın iç
                        sıcaklığını düşürme, görsel gizlilik sağlama, camların
                        çizilmesine karşı koruma ve UV ışınlarına karşı koruma
                        yer alır. Ayrıca, cam filmleri, araç içindeki insanların
                        güneş ışınlarından kaynaklanan zararlı etkileri azaltır
                        ve araçtaki değerli eşyaların güvenliğini sağlar.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {" "}
                        Sunroof Filmi nedir ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Sunroof filmi, otomobillerdeki açılır tavanlara
                        (sunroof) uygulanan bir film tabakasıdır. Bu film
                        tabakası, güneş ışınlarını engelleyerek aracın iç
                        mekanını serin tutar ve aynı zamanda ultraviyole (UV)
                        ışınlarına karşı koruma sağlar.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default FaqAccordion;
