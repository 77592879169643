import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import HomeSlider1 from "./../element/home-slider1";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      alert: "",
    };
  }
  handlePost(e) {
    e.preventDefault();
    if (this.state.name && this.state.email && this.state.message) {
      this.setState({
        alert: [
          "Mesajınız gönderiliyor, lütfen bekleyiniz...",
          "Your message is being sent, please wait...",
        ],
      });
      try {
        const url = "https://api.ahazgroup.com/api/Mail";
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
          }),
        };
        return fetch(url, options).then(() => {
          this.setState({ alert: "Mesajınız gönderildi, teşekkür ederiz!" });
        });
      } catch (error) {
        return this.setState({
          alert:
            "Mesajınız gönderilemedi, lütfen tekrar deneyiniz ya da sol tarafta bulunan diğer iletişim yöntemlerini deneyiniz.",
        });
      }
    } else {
      return this.setState({
        alert: "Lütfen gerekli tüm alanları doldurunuz!!!",
      });
    }
  }

  sendMail({ mailSubject, mailMessage }) {
    return (window.location.href =
      "mailto:" +
      "info@aprimeshields.com.tr" +
      "?subject=" +
      encodeURIComponent(mailSubject) +
      "&body=" +
      encodeURIComponent(mailMessage));
  }
  render() {
    return (
      <>
        <Header />

        <HomeSlider1 />

        {/* <!-- Welcome Setion --> */}
        <section className="welcome-section pt-0">
          <div className="auto-container">
            <div className="wrapper-box">
              <div className="row m-0">
                {/* <!-- Welcome Block One --> */}
                <div
                  className="welcome-block-one col-lg-4 wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <span className="flaticon-analysis"></span>
                    </div>
                    <h2>Ultra Koruma</h2>
                  </div>
                </div>
                {/* <!-- Welcome Block One --> */}
                <div
                  className="welcome-block-one col-lg-4 wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <span className="flaticon-tax"></span>
                    </div>
                    <h2>Kaliteli ve Ekonomik</h2>
                  </div>
                </div>
                {/* <!-- Welcome Block One --> */}
                <div
                  className="welcome-block-one col-lg-4 wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <span className="flaticon-star"></span>
                    </div>
                    <h2>Ultra Parlak</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container mb-5 ">
          {/* <ReactPlayer
            height={500}
            width={1200}
            // url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
            url="video.mp4"
          /> */}
          <video controls width="48%" height="500px%">
            <source src="/v1.mp4" type="video/mp4" />
          </video>
          <video
            style={{ marginLeft: "20px" }}
            controls
            width="48%"
            height="500px%"
          >
            <source src="/v2.mp4" type="video/mp4" />
          </video>
        </div>

        {/* <!-- About Section --> */}
        <section className="about-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      src={require("../../assets/images/resource/primeblack.jpeg")}
                      alt=""
                    />
                  </div>
                  <div
                    className="image-two wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  ></div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-box">
                  <div className="sec-title">
                    <div className="sub-title">Misyon ve Vizyonumuz</div>
                    <br></br>
                    <br></br>
                    <h3>Misyonumuz</h3>
                    <p className="text">
                      Araçlarını korumak isteyen müşterilerimize en yüksek
                      kaliteli ve en dayanıklı araç boya koruma filmlerini
                      sunmak. Müşterilerimizin araçlarını çevresel etkenlere
                      karşı korumak, aracın değerini korumak ve araçların
                      orijinal görünümünü korumak için en iyi hizmeti sağlamak.
                    </p>
                    <br></br>

                    <h3>Vizyonumuz</h3>

                    <p className="text">
                      Araç boya koruma filmleri alanında lider olmak ve
                      müşterilerimize en iyi hizmeti sunmak için sürekli olarak
                      araştırma ve geliştirme yapmak. Müşterilerimizin
                      araçlarına dair koruma ihtiyaçlarını karşılamak ve onların
                      memnuniyetini her zaman öncelikli tutmak. Ayrıca, araç
                      boya koruma filmlerimizin kalitesi ve dayanıklılığı ile
                      sektörde öncü olmak ve müşterilerimize en üst düzey
                      hizmeti sunmak için çalışmak.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Contact Section --> */}
        <section className="contact-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title">
                  <div className="sub-title">İletişime Geçin</div>
                </div>
                {/* <!-- Contact Form--> */}
                <div className="contact-form">
                  <form
                    method="post"
                    action="http://azim.commonsupport.com/Finandox/sendemail.php"
                    id="contact-form"
                  >
                    <div className="row clearfix">
                      <div className="col-md-12 form-group">
                        <label htmlFor="name">İsminiz</label>
                        <input
                          type="text"
                          name="username"
                          placeholder="İsim Giriniz..."
                          required=""
                          id="name"
                          onChange={(e) => {
                            this.setState({ name: e.target.value });
                            this.setState({ alert: "" });
                          }}
                        />
                        <i className="fas fa-user"></i>
                      </div>

                      <div className="col-md-12 form-group">
                        <label htmlFor="email">Mail Adresiniz</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Mail Adresi Giriniz..."
                          required=""
                          id="email"
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                            this.setState({ alert: "" });
                          }}
                        />
                        <i className="fas fa-envelope"></i>
                      </div>

                      <div className="col-md-12 form-group">
                        <label htmlFor="message">Mesajınız</label>
                        <textarea
                          name="message"
                          placeholder="Mesajınızı Yazınız..."
                          id="message"
                          onChange={(e) => {
                            this.setState({ message: e.target.value });
                            this.setState({ alert: "" });
                          }}
                        ></textarea>
                        <i className="fas fa-edit"></i>
                      </div>

                      <div className="col-md-12 form-group">
                        <button
                          className="theme-btn btn-style-one"
                          type="submit"
                          name="submit-form"
                          onClick={(e) => this.handlePost(e)}
                        >
                          <span className="btn-title">Gönder</span>
                        </button>
                      </div>
                    </div>
                    {this.state.alert && (
                      <div>
                        <div className="form-message-warning mt-4" />
                        <div className="form-message-success text-dark">
                          {this.state.alert}
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-info">
                  <div className="border-box">
                    <div className="border_top"></div>
                    <div className="border_middile"></div>
                  </div>
                  <div className="row">
                    <div className="info-column col-md-6">
                      <span
                        className=" contactIcon cursorPointer"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.sendMail({
                            mailSubject: "Tanışma",
                            mailMessage:
                              "Merhaba Prime Shields, \n \n Sizinle tanışmak istiyorum. \n Lütfen benimle iletişime geçiniz. \n \n Saygılarımla, \n",
                          })
                        }
                      >
                        <div className="icon-box">
                          <div className="icon">
                            <span className="flaticon-email-6"></span>
                          </div>
                          <h3>Email</h3>
                          <ul>info@primeshields.com.tr</ul>
                        </div>
                      </span>
                    </div>
                    <div className="info-column col-md-6">
                      <a
                        className=" contactIcon text-dark  "
                        target="_blank"
                        rel="noopener noreferrer"
                        href="tel:+905455536450"
                      >
                        <div className="icon-box">
                          <div className="icon">
                            <span className="flaticon-call-1"></span>
                          </div>
                          <h3>Telefon</h3>
                          <ul>+90 545 553 6450</ul>
                        </div>
                      </a>
                    </div>
                    <div className="info-column col-md-6">
                      <a
                        className="text-dark"
                        href="https://www.google.com/maps/place/1178.+Sk.+No:81,+06374+Ostim+Osb%2FYenimahalle%2FAnkara/@39.9691795,32.7467607,17z/data=!3m1!4b1!4m6!3m5!1s0x14d349bbfd2a56fb:0x2120250917516bc5!8m2!3d39.9691795!4d32.7467607!16s%2Fg%2F11csjhhjwz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="icon-box">
                          <div className="icon">
                            <span className="flaticon-location"></span>
                          </div>
                          <h3>Adres</h3>
                          <ul>
                            <span className="text-dark contactIcon">
                              Ostim OSB Mah. 1178 Sok. Nu:75/5 <br></br>{" "}
                              Yenimahalle/Ankara
                            </span>
                          </ul>
                        </div>
                      </a>
                    </div>
                    <div className="info-column col-md-6">
                      <a
                        className="text-dark"
                        href="https://www.primeshields.com.tr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="icon-box">
                          <div className="icon">
                            <span className="flaticon-worldwide"></span>
                          </div>
                          <h3>Web</h3>
                          <ul>
                            <span className="text-dark contactIcon">
                              www.primeshields.com.tr
                            </span>
                          </ul>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}
export default Index;
