import React, { Component } from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "../../assets/css/slider.css";

const slides = [
  {
    title: "",
    description: "",
    btnLearn: "-- Learn More --",
    finan: "",
    dox: "",
    btnService: "",
    image: require("./../../assets/images/main-slider/2.jpg"),
  },
  // {
  //   title: "",
  //   description:
  //     "",
  //   btnLearn: "",
  //   finan: "",
  //   dox: "",
  //   btnService: "",
  //   image: require("./../../assets/images/main-slider/3.jpg"),
  // },
];

class HomeSlider1 extends Component {
  render() {
    return (
      <>
        <Slider
          className="slider-wrapper"
          // autoplay
          touchDisabled={true}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                background: `url('${slide.image}') no-repeat center center`,
              }}
            ></div>
          ))}
        </Slider>
      </>
    );
  }
}
export default HomeSlider1;
